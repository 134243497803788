<template>
  <div class="container">
    <div v-if="educationProgrammeSupervisor_form.educationPrograms.length > 0" class="my-4">
      <h4 class="text-center my-3">Список ОП</h4>



      <DataTable :value="educationProgrammeSupervisor_form.educationPrograms" :paginator="true" :rows="10"
                 showGridlines
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,20,50]"
                 filterDisplay="menu"
                 :globalFilterFields="['education_speciality_name', 'education_speciality_code']"
                 v-model:filters="filters"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" stripedRows
                 responsiveLayout="scroll">

        <template #header>
          <div class="p-d-flex p-jc-between p-ai-center">
              <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" placeholder="Поиск ОП"/>
              </span>
          </div>
        </template>

        <Column field="education_speciality_code" header="Код ОП"></Column>

        <Column header="Название ОП">
          <template #body="{data}">
            <router-link
                :to="'/formation-education-program?education_program_id=' + data.id + '&decree_goso_template_id=' + data.decree_goso_template_id">
              {{ data.education_speciality_name }}
            </router-link>
          </template>
        </Column>
        <Column field="language_name" header="Язык обучения">
          <template #body="{data}">
            {{ data.language_name ? data.language_name : '' }}
          </template>
          <template #filter="{filterModel}">
            <Dropdown v-model="filterModel.value" :options="studyLanguages" placeholder="Любой"
                      class="p-column-filter" :showClear="true">
              <template #value="slotProps">
                <span v-if="slotProps.value">{{ slotProps.value }}</span>
                <span v-else>{{ slotProps.placeholder }}</span>
              </template>
              <template #option="slotProps">
                <span>{{ slotProps.option }}</span>
              </template>
            </Dropdown>
          </template>
        </Column>

        <Column field="admission_year" header="Год набора">

          <template #filter="{filterModel}">
            <Dropdown v-model="filterModel.value" :options="admissionYears" placeholder="Любой"
                      class="p-column-filter" :showClear="true">
              <template #value="slotProps">
                <span v-if="slotProps.value">{{ slotProps.value }}</span>
                <span v-else>{{ slotProps.placeholder }}</span>
              </template>
              <template #option="slotProps">
                <span>{{ slotProps.option }}</span>
              </template>
            </Dropdown>
          </template>
        </Column>
        <Column header="Название приказа ГОСО">
          <template #body="{data}">
            {{ data.decree_goso_template ? data.decree_goso_template : '' }}
          </template>
        </Column>
        <Column header="Результаты обучения">
          <template #body="{data}">
            <router-link :to="'/education-program-result?education_program_id=' + data.id" target="_blank">
              Перейти
            </router-link>
          </template>
        </Column>
        <Column header="Редактировать">
          <template #body="{data}">
            <button type="button" class="btn btn-warning" data-bs-toggle="modal"
                    data-bs-target="#updateEducationProgram"
                    @click="updateEducation(data.id)">
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
            </button>
          </template>
        </Column>


      </DataTable>



    </div>
    <div v-else>
      Нет ОП
    </div>
  </div>
</template>


<script>
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "EducationProgrammeSupervisor",
  data() {
    return {

      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'education_speciality_name': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'education_speciality_code': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'admission_year': {value: null, matchMode: FilterMatchMode.EQUALS},
        'language_name': {value: null, matchMode: FilterMatchMode.EQUALS},

      },
      studyLanguages: ['русский язык', 'қазақ тілі', 'English'],
      admissionYears: [2018, 2019, 2020, 2021, 2022, 2023],
      year: 2023
      //educationPrograms: []
    }
  },
  computed: {
    ...mapState('educationProgrammeSupervisor', ['educationProgrammeSupervisor_form']),
  },
  methods: {
    ...mapActions('educationProgrammeSupervisor', ['GET_SUPERVISOR_EDUCATION_PROGRAMS',]),
    //...mapMutations('educationProgrammeSupervisor', ['SET_UPDATE_EDUCATION_PROGRAM_RESULT']),
  },

  async mounted() {
    await this.GET_SUPERVISOR_EDUCATION_PROGRAMS()
  }
}
</script>
<style scoped>
</style>
